import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import CiroTooltipContainer from "./CiroTooltipContainer";
import approx from "approximate-number";

interface ICiroPaginationProps {
  setOffset: (v: number) => void;
  offset: number;
  perPage: number;
  totalCount: number;
  paginationLimit?: number;
}

const CiroPagination = ({
  offset,
  perPage,
  setOffset,
  totalCount,
  paginationLimit,
}: ICiroPaginationProps) => {
  const getPage = useCallback(() => {
    return Math.floor(offset / perPage) + 1;
  }, [offset, perPage]);
  const currentPage = useMemo(getPage, [getPage]);
  const [paginationPage, setPaginationPage] = useState(getPage());
  const [displayCenterTooltip, setDisplayCenterTooltip] = useState(false);
  useEffect(() => {
    setPaginationPage(getPage());
  }, [getPage, offset]);

  const maxPages = Math.ceil(totalCount / perPage);
  const validPaginationPage = paginationLimit === undefined || paginationPage <= paginationLimit;

  return (
    <span
      className={classNames([
        "ciro-v1-flex",
        "ciro-v1-justify-between",
        "ciro-v1-items-center",
      ])}
    >
      <CiroButton
        customClassName={classNames("ciro-v1-mr-2", "sm:ciro-v1-mr-0")}
        onClick={() => {
          setOffset(Math.max(0, offset - perPage));
        }}
        disabled={currentPage < 2}
        analyticsField="Previous Page"
        style={CiroButtonStyleEnum.UNSTYLED}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-p-3",
          )}
        >
          <ArrowLeftIcon className={classNames("ciro-v1-h-5")} />
          <span className="ciro-v1-pl-2">Previous</span>
        </div>
      </CiroButton>
      <CiroTooltipContainer
        tooltip={
          <>
            <div>Pagination past page {paginationLimit} is disabled. </div>
            <div>Upgrade your account to view additional pages</div>
          </>
        }
        visible={displayCenterTooltip}
        disabled={paginationLimit === undefined || currentPage + 1 <= paginationLimit}
      >
        <span>
          Page{" "}
          <input
            className={classNames(
              [
                "ciro-v1-border-1",
                "ciro-v1-pl-3",
                "ciro-v1-rounded",
                "ciro-v1-w-14",
              ],
              {
                "ciro-v1-border-gray-300": validPaginationPage,
                "ciro-v1-border-rose-500": !validPaginationPage,
                "focus:ciro-v1-border-gray-300": validPaginationPage,
                "focus:ciro-v1-border-rose-500": !validPaginationPage,
              },
            )}
            type="number"
            value={paginationPage}
            onChange={(e) => {
              setPaginationPage(Number(e.target.value || 1));
              setDisplayCenterTooltip(false);
            }}
            min={1}
            max={maxPages}
            onKeyDown={(e) => {
              if (!validPaginationPage) {
                setDisplayCenterTooltip(true);
                setTimeout(() => setDisplayCenterTooltip(false), 2000);
                return;
              }
              if (e.key === "Enter") {
                setOffset((paginationPage - 1) * perPage);
              }
            }}
          />{" "}
          of {approx(maxPages)}
        </span>
      </CiroTooltipContainer>
      <CiroTooltipContainer
        tooltip={
          <>
            <div>Pagination past page {paginationLimit} is disabled. </div>
            <div>Upgrade your account to view additional pages</div>
          </>
        }
        disabled={paginationLimit === undefined || currentPage + 1 <= paginationLimit}
      >
        <CiroButton
          onClick={() => {
            if (paginationLimit === undefined || currentPage < paginationLimit) {
              setOffset(Math.min(offset + perPage, (paginationLimit || maxPages) * perPage));
            }
          }}
          disabled={paginationLimit !== undefined && currentPage + 1 > Math.min(paginationLimit, maxPages)}
          analyticsField="Next Page"
          style={CiroButtonStyleEnum.UNSTYLED}
        >
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-p-3",
            )}
          >
            <span className="ciro-v1-pr-2">Next</span>
            <ArrowRightIcon className={classNames("ciro-v1-h-5")} />
          </div>
        </CiroButton>
      </CiroTooltipContainer>
    </span>
  );
};

export default CiroPagination;
