import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import CiroTextInput from "./CiroTextInput";
import classNames from "classnames";

interface ICiroDatePickerProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  maxDate?: Date | null;
  minDate?: Date | null;
  placeholderText: string;
  disabled?: boolean;
}

interface IDateInputFieldProps {
  onChange?: () => void;
  onClick?: () => void;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

const DateInputField = forwardRef(
  (
    { placeholder, onClick, value, onChange, disabled }: IDateInputFieldProps,
    ref: any,
  ) => {
    return (
      <CiroTextInput
        className={classNames("ciro-v1-w-full")}
        type="text"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        ref={ref}
        onChange={onChange}
        disabled={disabled}
      />
    );
  },
);

const CiroDatePicker = ({
  selectedDate,
  setSelectedDate,
  maxDate = null,
  minDate = null,
  placeholderText,
  disabled = false,
}: ICiroDatePickerProps) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(v) => {
        setSelectedDate(v);
      }}
      maxDate={maxDate}
      minDate={minDate}
      placeholderText={placeholderText}
      isClearable={!disabled}
      disabled={disabled}
      showDisabledMonthNavigation
      portalId="root"
      customInput={<DateInputField />}
    />
  );
};

export default CiroDatePicker;
