import { useQuery } from "@apollo/client";
import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import ContactListTable, {
  ContactListTable_ContactList,
} from "../../components/automation/ContactListTable";
import { gql } from "@apollo/client";
import {
  Automation_ContactListsQuery,
  Automation_PersonasQuery,
  ContactListTable_ContactListFragment,
  PersonaTable_PersonaFragment,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import AutomationSplashPage from "./AutomationSplashPage";
import UpsertContactListModal from "../../components/automation/UpsertContactListModal";
import { useState } from "react";
import AutomationHomeTableSection from "../../components/automation/AutomationHomeTableSection";
import PersonaTable, { PersonaTable_Persona } from "../../components/automation/PersonaTable";
import UpsertPersonaModal from "../../components/automation/UpsertPersonaModal";

export const Automation_ContactLists = gql`
  query Automation_ContactLists {
    contactLists {
      ...ContactListTable_ContactList
    }
  }
  ${ContactListTable_ContactList}
`;

export const Automation_Personas = gql`
  query Automation_Personas {
    personas {
      ...PersonaTable_Persona
    }
  }
  ${PersonaTable_Persona}
`;

const Automation = () => {
  const { data: contactListsData, loading: loadingContactLists, refetch: refetchContactLists } = useQuery<Automation_ContactListsQuery>(
    Automation_ContactLists,
  );
  const { data: personasData, loading: loadingPersonas, refetch: refetchPersonas } = useQuery<Automation_PersonasQuery>(
    Automation_Personas,
  );
  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const [isCreatePersonaModalOpen, setIsCreatePersonaModalOpen] = useState(false);

  const contactLists = contactListsData?.contactLists as ContactListTable_ContactListFragment[];
  const personas = personasData?.personas as PersonaTable_PersonaFragment[];
  
  return (
    <>
      {loadingContactLists ? (
        <SkeletonLoading
          numSkeletons={5}
          skeletonHeight={"3rem"}
          containerCustomerClassName={classNames(
            "ciro-v1-mx-20",
            "ciro-v1-my-10",
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-gap-6",
          )}
        />
      ) : (!contactLists || contactLists.length === 0) && (!personas || personas.length === 0) ? (
        <AutomationSplashPage 
          onCreateNewList={() => {
            setIsCreateListModalOpen(true)
            console.log("opened modal!")
          }}
        />
      ) : (
        <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-justify-between",
              "ciro-v1-w-full",
              "ciro-v1-pb-6",
              "ciro-v1-mb-4",
              "ciro-v1-border-b-1",
              "ciro-v1-border-gray-100",
            )}
          >
            <CiroTitle title="Autopilot" subtitle="Create lists and have Ciro automatically populate them with leads from LinkedIn." />
          </div>
          <AutomationHomeTableSection
            objectType="list"
            table={
              <ContactListTable
                contactLists={contactLists}
                loading={loadingContactLists}
                refetch={refetchContactLists}
              />
            }
            buttonHandler={() => setIsCreateListModalOpen(true)}
          />
          <AutomationHomeTableSection
            objectType="persona"
            table={
              personas && personas.length > 0 ? <PersonaTable personas={personas} loading={loadingPersonas} refetch={refetchPersonas} /> : undefined
            }
            buttonHandler={() => setIsCreatePersonaModalOpen(true)}
          />
        </CiroContainer>
      )}
      <UpsertContactListModal
        isOpen={isCreateListModalOpen}
        onClose={() => setIsCreateListModalOpen(false)}
        onSuccess={async () => {
          await refetchContactLists();
          setIsCreateListModalOpen(false);
        }}
      />
      <UpsertPersonaModal
        isOpen={isCreatePersonaModalOpen}
        onClose={() => setIsCreatePersonaModalOpen(false)}
        onSuccess={async () => {
          await refetchPersonas();
          setIsCreatePersonaModalOpen(false);
        }}
      />
    </>
  );
};

export default Automation;
