import { gql, useMutation, useQuery } from "@apollo/client";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroNumbersFormSection from "../../components/shared/CiroNumbersFormSection";
import CiroDropDown from "../../components/shared/CiroDropdown";
import CiroTextInput from "../../components/shared/CiroTextInput";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import classNames from "classnames";
import { useState, useEffect } from "react";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CiroSpinner from "../../components/shared/CiroSpinner";
import CiroSwitch from "../../components/shared/CiroSwitch";
import CiroLink from "../../components/shared/CiroLink";
import { Profile_UserAccount } from "./Profile";

const PROVIDERS = [
  { value: "apollo", label: "Apollo" },
  { value: undefined, label: "More coming soon!" },
];

const ProfileDataProviderConnectSettings_AddOrganizationDataSource = gql`
  mutation AddOrganizationDataSource($input: AddOrganizationDataSourceInput!) {
    addOrganizationDataSource(input: $input) {
      id
      data_source
      client_id
      api_key
      validate_numbers
    }
  }
`;

const ProfileDataProviderConnectSettings_DeleteOrganizationDataSource = gql`
  mutation DeleteOrganizationDataSource($id: Int!) {
    deleteOrganizationDataSource(id: $id)
  }
`;

const ProfileDataProviderConnectSettings_UpdateOrganizationDataSource = gql`
  mutation UpdateOrganizationDataSource(
    $id: Int!
    $input: UpdateOrganizationDataSourceInput!
  ) {
    updateOrganizationDataSource(id: $id, input: $input) {
      id
      data_source
      api_key
      validate_numbers
    }
  }
`;

const ProfileDataProviderConnectionSettings_GetOrganizationDataSources = gql`
  query ProfileDataProviderConnectionSettings_GetOrganizationDataSources {
    userAccount {
      org {
        apolloIntegration {
          api_key
        }
        organizationDataSources {
          id
          data_source
          api_key
          validate_numbers
        }
      }
    }
  }
`;

const ProfileDataProviderConnectionSettingsSchema = yup.object({
  provider: yup.string().required("Provider is required"),
  apiKey: yup.string().required("API key is required"),
  provideQualityChecks: yup.boolean().default(false),
});

const ProfileDataProviderConnectionSettings = () => {
  const navigator = useNavigate();
  const {
    data: orgDataSourcesData,
    loading: loadingDataSources,
    refetch,
  } = useQuery(
    ProfileDataProviderConnectionSettings_GetOrganizationDataSources,
  );

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ProfileDataProviderConnectionSettingsSchema),
    defaultValues: {
      provider: "apollo" as string | undefined,
      apiKey: undefined as string | undefined,
      provideQualityChecks: false,
    },
    mode: "onBlur",
  });

  const [existingDataSourceId, setExistingDataSourceId] = useState<
    number | null
  >(null);

  const [addOrganizationDataSource, { loading }] = useMutation(
    ProfileDataProviderConnectSettings_AddOrganizationDataSource,
    // Using refetchQueries (instead of refetch callback) since this this component
    // doesn't have access to refetchUserAccount from profile page
    {
      refetchQueries: [Profile_UserAccount],
    },
  );

  const [deleteOrganizationDataSource] = useMutation(
    ProfileDataProviderConnectSettings_DeleteOrganizationDataSource,
    {
      refetchQueries: [Profile_UserAccount],
    },
  );

  const [updateOrganizationDataSource] = useMutation(
    ProfileDataProviderConnectSettings_UpdateOrganizationDataSource,
    {
      refetchQueries: [Profile_UserAccount],
    },
  );

  const [deleting, setDeleting] = useState(false);

  const [initialValues, setInitialValues] = useState({
    provider: "",
    apiKey: "",
    provideQualityChecks: false,
  });

  const [provider, apiKey, provideQualityChecks] = useWatch({
    control,
    name: ["provider", "apiKey", "provideQualityChecks"],
  });

  useEffect(() => {
    if (orgDataSourcesData?.userAccount?.org?.organizationDataSources?.[0]) {
      const dataSource =
        orgDataSourcesData.userAccount.org.organizationDataSources[0];
      setValue("provider", dataSource.data_source);
      setValue("apiKey", dataSource.api_key);
      setValue("provideQualityChecks", dataSource.validate_numbers);
      setExistingDataSourceId(dataSource.id);
      setInitialValues({
        provider: dataSource.data_source,
        apiKey: dataSource.api_key,
        provideQualityChecks: dataSource.validate_numbers,
      });
    } else if (
      orgDataSourcesData?.userAccount?.org?.apolloIntegration?.api_key
    ) {
      setValue(
        "apiKey",
        orgDataSourcesData.userAccount.org.apolloIntegration.api_key,
      );
    }
  }, [orgDataSourcesData, setValue]);

  const hasFormChanged = () => {
    if (!existingDataSourceId) {
      return !!provider && !!apiKey;
    }
    return initialValues.provideQualityChecks !== provideQualityChecks;
  };

  const onSubmit = async (data: any) => {
    try {
      if (existingDataSourceId) {
        await updateOrganizationDataSource({
          variables: {
            id: existingDataSourceId,
            input: {
              validate_numbers: data.provideQualityChecks,
            },
          },
          onCompleted: () => {
            toast.success("Data provider connection updated");
            refetch();
            navigator("/profile");
          },
        });
      } else {
        await addOrganizationDataSource({
          variables: {
            input: {
              data_source: data.provider,
              api_key: data.apiKey,
              index: 0,
              validate_numbers: data.provideQualityChecks,
              existing_apollo_integration:
                !!orgDataSourcesData?.userAccount?.org?.apolloIntegration
                  ?.api_key,
            },
          },
          onCompleted: () => {
            toast.success("Data provider connection added");
            refetch();
            navigator("/profile");
          },
        });
      }
    } catch (error: any) {
      if (error.message === "Request failed with status code 401") {
        toast.error("Invalid API key");
      } else {
        toast.error("Failed to add data provider connection");
      }
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    
    if (!existingDataSourceId) return;

    await deleteOrganizationDataSource({
      variables: { id: existingDataSourceId },
      onCompleted: () => {
        setDeleting(false);
        toast.success("Data provider connection removed");
        navigator("/profile");
      },
    });
  };

  return (
    <CiroContainer className={classNames(["ciro-v1-bg-zinc-100"])}>
      <CiroBreadCrumbs
        values={["Profile", "Data provider connection"]}
        href="/profile"
      />
      <h1
        className={classNames(
          "ciro-v1-text-2xl",
          "ciro-v1-font-medium",
          "ciro-v1-border-b",
          "ciro-v1-pb-4",
          "ciro-v1-w-full",
          "ciro-v1-mb-4",
        )}
      >
        Data provider connection
      </h1>
      {loadingDataSources ? (
        <CiroSpinner loading={true} />
      ) : (
        <CiroNumbersFormSection
          title={`${
            !!existingDataSourceId ? "Manage connection" : "Connect provider"
          }`}
          description={`${
            !!existingDataSourceId
              ? "Manage your data provider connection."
              : "Add an API key to check an existing data provider before Ciro."
          }`}
        >
          <div>
            <CiroDropDown
              label="Select provider"
              options={PROVIDERS}
              value={provider}
              onChange={(v) => setValue("provider", v)}
              error={errors.provider?.message}
              isDisabled={true}
            />
            <div className={classNames("ciro-v1-mt-4")}>
              <CiroTextInput
                label="API key"
                value={apiKey}
                onChange={(v) => setValue("apiKey", v.target.value)}
                error={errors.apiKey?.message}
                disabled={
                  !!existingDataSourceId ||
                  orgDataSourcesData?.userAccount?.org?.apolloIntegration
                    ?.api_key
                }
              />
              {!existingDataSourceId &&
                orgDataSourcesData?.userAccount?.org?.apolloIntegration
                  ?.api_key && (
                  <p
                    className={classNames(
                      "ciro-v1-text-sm",
                      "ciro-v1-text-gray-500",
                      "ciro-v1-mt-1",
                    )}
                  >
                    API key provided by existing{" "}
                    <CiroLink href="/enrich-crm/apollo-integration">
                      integration
                    </CiroLink>
                  </p>
                )}
            </div>
            <div className={classNames("ciro-v1-mt-4")}>
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-justify-between",
                  "ciro-v1-items-center",
                )}
              >
                <div>
                  <p className={classNames("ciro-v1-font-medium")}>
                    Provide quality checks?
                  </p>
                  <p
                    className={classNames(
                      "ciro-v1-text-sm",
                      "ciro-v1-text-gray-500",
                      "ciro-v1-max-w-[400px]",
                    )}
                  >
                    If turned on, we'll run every number through our
                    verification service to ensure quality.
                  </p>
                </div>
                <CiroSwitch
                  checked={provideQualityChecks}
                  onChange={(checked) =>
                    setValue("provideQualityChecks", checked)
                  }
                />
              </div>
            </div>
            {existingDataSourceId && (
              <div className={classNames("ciro-v1-mt-8")}>
                <p
                  className={classNames("ciro-v1-mb-2", "ciro-v1-font-medium")}
                >
                  Want to remove this connection?
                </p>
                <CiroButton
                  style={CiroButtonStyleEnum.DANGER}
                  analyticsField="remove_data_provider"
                  onClick={handleDelete}
                  disabled={deleting}
                >
                  {deleting ? "Deleting..." : "Remove connection"}
                </CiroButton>
              </div>
            )}
          </div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-justify-end",
              "ciro-v1-mt-4",
              "ciro-v1-border-t",
              "ciro-v1-w-full",
              "ciro-v1-pt-6",
            )}
          >
            <CiroButton
              analyticsField="cancel_data_provider"
              customClassName={classNames("ciro-v1-mr-2")}
              onClick={() => navigator("/profile")}
            >
              Cancel
            </CiroButton>
            <CiroButton
              style={CiroButtonStyleEnum.LOUD}
              analyticsField="save_data_provider"
              disabled={loading || !hasFormChanged()}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? (
                <CiroSpinner loading={true} />
              ) : !!existingDataSourceId ? (
                "Save"
              ) : (
                "Save and test connection"
              )}
            </CiroButton>
          </div>
        </CiroNumbersFormSection>
      )}
    </CiroContainer>
  );
};

export default ProfileDataProviderConnectionSettings;
