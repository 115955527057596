import React, { useState } from "react";
import classNames from "classnames";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { gql, useMutation } from "@apollo/client";
import {
  DestroyContactListModal_DestroyContactListMutation,
  DestroyContactListModal_DestroyContactListMutationVariables,
} from "../../__generated__/graphql";
import toast from "react-hot-toast";
import CiroSpinner from "../shared/CiroSpinner";

const DestroyContactListModal_DestroyContactList = gql`
  mutation DestroyContactListModal_DestroyContactList($id: Int!) {
    destroyContactList(id: $id) {
      success
      message
    }
  }
`;

interface IDeleteContactListModalProps {
  isOpen: boolean;
  listId: number;
  listName: string;
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

const DeleteContactListModal = ({
  isOpen,
  listId,
  listName,
  onClose,
  onSuccess,
}: IDeleteContactListModalProps) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [destroyContactList, { loading }] = useMutation<
    DestroyContactListModal_DestroyContactListMutation,
    DestroyContactListModal_DestroyContactListMutationVariables
  >(DestroyContactListModal_DestroyContactList, {
    async onCompleted(data) {
      if (data.destroyContactList.success) {
        setIsRefetching(true);
        try {
          await onSuccess();
          toast.success("List deleted successfully.");
        } finally {
          setIsRefetching(false);
          onClose();
        }
      }
    }
  });

  const handleDelete = async () => {
    try {
      await destroyContactList({
        variables: {
          id: listId,
        },
      });
    } catch (error) {
      toast.error("Error deleting contact list.");
      console.error("Error deleting contact list:", error);
    }
  };

  const isLoading = loading || isRefetching;

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-gap-4",
        )}
      >
        <div className={classNames("ciro-v1-text-lg", "ciro-v1-font-medium")}>
          Delete list
        </div>
        <div className="ciro-v1-flex ciro-v1-flex-col ciro-v1-gap-2">
          <div>
            Are you sure you want to delete <span className="ciro-v1-font-semibold">{listName}</span>?
          </div>
          <div className="ciro-v1-text-gray-600">
            This will remove all contacts from this list.
          </div>
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-gap-4",
          )}
        >
          <CiroButton
            analyticsField="cancel-delete-list"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="confirm-delete-list"
            style={CiroButtonStyleEnum.DANGER}
            onClick={handleDelete}
            disabled={isLoading}
          >
            {isLoading ? (
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-gap-2",
                )}
              >
                <CiroSpinner loading={isLoading || isRefetching} />
                <div>Deleting...</div>
              </div>
            ) : (
              "Yes, delete"
            )}
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default DeleteContactListModal; 