import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CiroSpinner from "../shared/CiroSpinner";

const UpsertPersonaModal_UpsertPersona = gql`
  mutation UpsertPersonaModal_UpsertPersona($input: UpsertPersonaInput!) {
    upsertPersona(input: $input) {
      success
      message
      persona {
        id
        name
        description
      }
    }
  }
`;

interface IUpsertPersonaModal {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  initialData?: {
    id: number;
    name: string;
    description: string;
  };
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

const UpsertPersonaModal = ({
  isOpen,
  onClose,
  onSuccess,
  initialData,
}: IUpsertPersonaModal) => {
  const [isRefetching, setIsRefetching] = useState(false);
  
  const { register, handleSubmit: handleFormSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || "",
      description: initialData?.description || "",
    },
  });


  const [upsertPersona, { loading }] = useMutation(UpsertPersonaModal_UpsertPersona, {
    async onCompleted(data) {
      if (data.upsertPersona.success) {
        setIsRefetching(true);
        try {
          await onSuccess();
          toast.success(initialData ? "Persona updated successfully." : "Persona created successfully.");
          setValue("name", "");
          setValue("description", "");
        } finally {
          setIsRefetching(false);
          onClose();
        }
      } else {
        toast.error(data.upsertPersona.message || "Error saving persona.");
      }
    }
  });

  const handleSubmit = handleFormSubmit(async (data) => {
    try {
      await upsertPersona({
        variables: {
          input: {
            ...(initialData?.id && { id: initialData.id }),
            name: data.name.trim(),
            description: data.description.trim(),
          },
        },
      });
    } catch (error) {
      console.error("Error updating persona: ", error);
      toast.error("Error updating persona.");
    }
  });

  const isLoading = loading || isRefetching;

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-gap-4",
        )}
      >
        <div className={classNames("ciro-v1-text-lg", "ciro-v1-font-medium")}>
          {initialData ? "Edit persona" : "Create new persona"}
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-gap-2",
          )}
        >
          <label htmlFor="name" className="ciro-v1-text-sm ciro-v1-font-medium">
            Name
          </label>
          <input
            id="name"
            type="text"
            {...register("name")}
            className={classNames(
              "ciro-v1-rounded-md",
              "ciro-v1-border",
              "ciro-v1-border-gray-300",
              "ciro-v1-px-3",
              "ciro-v1-py-2",
              "focus:ciro-v1-border-blue-500",
              "focus:ciro-v1-outline-none",
            )}
            placeholder="Engineering decision-makers"
            disabled={isLoading}
          />
          {errors.name && (
            <span className="ciro-v1-text-red-500 ciro-v1-text-sm">
              {errors.name.message}
            </span>
          )}
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-gap-2",
          )}
        >
          <label
            htmlFor="description"
            className="ciro-v1-text-sm ciro-v1-font-medium"
          >
            Description
          </label>
          <textarea
            id="description"
            {...register("description")}
            className={classNames(
              "ciro-v1-rounded-md",
              "ciro-v1-border",
              "ciro-v1-border-gray-300",
              "ciro-v1-px-3",
              "ciro-v1-py-2",
              "focus:ciro-v1-border-blue-500",
              "focus:ciro-v1-outline-none",
            )}
            placeholder="CTOs and VPs of engineering at Series B and C B2B SaaS companies..."
            disabled={isLoading}
          />
          {errors.description && (
            <span className="ciro-v1-text-red-500 ciro-v1-text-sm">
              {errors.description.message}
            </span>
          )}
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-gap-4",
          )}
        >
          <CiroButton
            onClick={onClose}
            disabled={isLoading}
            analyticsField="Cancel update persona"
          >
            Cancel
          </CiroButton>
          <CiroButton
            style={CiroButtonStyleEnum.LOUD}
            onClick={handleSubmit}
            analyticsField="Save persona"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CiroSpinner loading={isLoading} />
                <span className="ciro-v1-ml-2">Saving...</span>
              </>
            ) : initialData ? (
              "Save changes"
            ) : (
              "Create persona"
            )}
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default UpsertPersonaModal; 