import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroDatePicker from "../shared/CiroDatePicker";
import SkeletonLoading from "../shared/SkeletonLoading";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { format, subWeeks } from "date-fns";
import {
  MobileNumbersRunModal_ContactsToEnrichQuery,
  MobileNumbersRunModal_ContactsToEnrichQueryVariables,
  MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutation,
  MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutationVariables,
  MobileNumbersRunModal_StartNewPhoneNumberRequestJobMutationVariables,
  MobileNumbersRunModal_StartNewPhoneNumberRequestJobMutation,
  MobileNumbersRunModal_WorkflowsQuery,
  MobileNumbersRunModal_WorkflowsQueryVariables,
  MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragmentDoc,
  PhoneNumberRequestSegmentTypeEnum,
} from "../../__generated__/graphql";
import { MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions } from "./MobileNumbersTable/MobileNumbersTransactionsTable";
import CiroDropdown from "../shared/CiroDropdown";
import MobileNumbersRunPreviewSelection, {
  MobileNumbersRunPreviewSelection_PhoneNumberRequests,
} from "./MobileNumbersRunPreviewSelection";
import { useFragment } from "../../__generated__";
import CiroSwitch from "../shared/CiroSwitch";
import pluralize from "pluralize";
import LoadingBar, { loadingBarHeight } from "../shared/LoadingBar";

const MobileNumbersRunModal_StartNewPhoneNumberRequestJob = gql`
  mutation MobileNumbersRunModal_StartNewPhoneNumberRequestJob(
    $workflowId: Int!
    $after: Date
  ) {
    startFindNewPhoneNumberRequestsJob(workflowId: $workflowId, after: $after) {
      jobId
    }
  }
`;

const MobileNumbersRunModal_ContactsToEnrich = gql`
  query MobileNumbersRunModal_ContactsToEnrich(
    $phoneNumberRequestJobId: String!
  ) {
    newPhoneNumberRequestsStatus(
      phoneNumberRequestJobId: $phoneNumberRequestJobId
    ) {
      progress
      creditsPerRequest
      errorMessage
      phoneNumberRequests {
        org_phone_call_id
        orgContact {
          first_name
          company_website_domain
          last_name
          linkedin_id
          external_object_type
          external_id
          company_name
          source
          orgPhoneNumbers {
            phone_number
            external_phone_field
            status
            external_status
          }
          lastEnrichedByCiroAt
        }
      }
      ...MobileNumbersRunPreviewSelection_PhoneNumberRequests
      creditsNeeded
    }
  }
  ${MobileNumbersRunPreviewSelection_PhoneNumberRequests}
`;

const MobileNumbersRunModal_Workflows = gql`
  query MobileNumbersRunModal_Workflows {
    organization {
      phoneNumberRequestWorkflows {
        id
        name
        segmentationType
        org_contact_object_type
        filter_logic {
          filter_object
          filter_property
          filter_type
          filter_op
          filter_value
        }
      }
      credit_plan {
        balance
      }
    }
  }
`;

const MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction = gql`
  mutation MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction(
    $phoneNumberRequests: [PhoneNumberRequestInput!]!
    $workflowId: Int!
  ) {
    createNewPhoneNumbersRequestTransaction(
      phoneNumberRequests: $phoneNumberRequests
      workflowId: $workflowId
    ) {
      id
    }
  }
`;

interface IMobileNumbersRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  startingWorkflowId?: number | null;
}

const DEFAULT_LOOK_BACK_PERIOD = subWeeks(new Date(), 2);

const MobileNumbersRunModal = ({
  isOpen,
  onClose,
  startingWorkflowId = null,
}: IMobileNumbersRunModalProps) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    startingWorkflowId,
  );

  const [segmentationType, setSegmentationType] =
    useState<PhoneNumberRequestSegmentTypeEnum | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [includePreviouslyEnriched, setIncludePreviouslyEnriched] =
    useState(false);

  useEffect(() => {
    setSelectedWorkflowId(startingWorkflowId);
    setIncludePreviouslyEnriched(false);
  }, [startingWorkflowId]);

  const [
    newPhoneNumberRequestsLoadingProgress,
    setNewPhoneNumberRequestsLoadingProgress,
  ] = useState(0);

  const [jobId, setJobId] = useState<string | null>(null);

  const [
    startNewPhoneNumberRequestJob,
    { loading: startNewPhoneNumberRequestJobLoading },
  ] = useMutation<
    MobileNumbersRunModal_StartNewPhoneNumberRequestJobMutation,
    MobileNumbersRunModal_StartNewPhoneNumberRequestJobMutationVariables
  >(MobileNumbersRunModal_StartNewPhoneNumberRequestJob);

  const [getContactsToEnrich, { data: contactsToEnrichData }] = useLazyQuery<
    MobileNumbersRunModal_ContactsToEnrichQuery,
    MobileNumbersRunModal_ContactsToEnrichQueryVariables
  >(MobileNumbersRunModal_ContactsToEnrich, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setNewPhoneNumberRequestsLoadingProgress(
      (contactsToEnrichData?.newPhoneNumberRequestsStatus?.progress || 0) / 100,
    );
  }, [contactsToEnrichData]);

  const pollContactsToEnrich = useCallback(
    async (jobId: string) => {
      let isCancelled = false;

      const poll = async () => {
        if (isCancelled) return;

        const response = await getContactsToEnrich({
          variables: {
            phoneNumberRequestJobId: jobId,
          },
        });

        if (
          !isCancelled &&
          response.data?.newPhoneNumberRequestsStatus &&
          response.data?.newPhoneNumberRequestsStatus?.progress !== 100
        ) {
          setTimeout(poll, 1000);
        }
      };

      poll();

      return () => {
        isCancelled = true;
      };
    },
    [getContactsToEnrich],
  );

  useEffect(() => {
    if (jobId) {
      pollContactsToEnrich(jobId);
    }
  }, [pollContactsToEnrich, jobId]);

  const [selectedContactIds, setSelectedContactIds] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    setSelectedContactIds(
      new Set(
        contactsToEnrichData?.newPhoneNumberRequestsStatus?.phoneNumberRequests
          ?.filter(
            (request) =>
              includePreviouslyEnriched ||
              !request.orgContact.lastEnrichedByCiroAt,
          )
          .map((request) => request.orgContact.external_id),
      ),
    );
  }, [contactsToEnrichData, includePreviouslyEnriched]);

  const { data: workflowsData, loading: workflowsLoading } = useQuery<
    MobileNumbersRunModal_WorkflowsQuery,
    MobileNumbersRunModal_WorkflowsQueryVariables
  >(MobileNumbersRunModal_Workflows);

  const workflowOptions = useMemo(() => {
    if (workflowsData?.organization?.phoneNumberRequestWorkflows) {
      return workflowsData.organization?.phoneNumberRequestWorkflows.map(
        (workflow) => ({
          label: workflow.name,
          value: workflow.id,
        }),
      );
    }
    return [];
  }, [workflowsData]);

  const workflowIdMap = useMemo(() => {
    if (!workflowsData?.organization?.phoneNumberRequestWorkflows) {
      return {};
    }
    return workflowsData.organization.phoneNumberRequestWorkflows.reduce(
      (acc, workflow) => {
        acc[workflow.id] = {
          id: workflow.id,
          value: workflow.name,
          segmentationType: workflow.segmentationType,
          org_contact_object_type: workflow.org_contact_object_type,
        };
        return acc;
      },
      {} as Record<
        number,
        {
          id: number;
          value: string;
          segmentationType: PhoneNumberRequestSegmentTypeEnum;
          org_contact_object_type: string;
        }
      >,
    );
  }, [workflowsData]);

  const callsOrContacts = useMemo(() => {
    if (!selectedWorkflowId) {
      if (startingWorkflowId) {
        const workflow = workflowIdMap[startingWorkflowId];
        return workflow?.segmentationType ===
          PhoneNumberRequestSegmentTypeEnum.CallDisposition
          ? "calls"
          : "contacts";
      }
      // This happens in the case where the user has the option to select a workflow,
      // but the selection has been cleared (or they haven't selected yet).
      return "records";
    }
    const workflow = workflowIdMap[selectedWorkflowId];
    if (
      workflow.segmentationType ===
      PhoneNumberRequestSegmentTypeEnum.CrmRecordValues
    ) {
      return pluralize(workflow.org_contact_object_type).toLowerCase();
    }
    return "calls";
  }, [selectedWorkflowId, startingWorkflowId, workflowIdMap]);

  const [
    createPhoneNumbersRequestTransaction,
    {
      loading: createPhoneNumbersRequestTransactionLoading,
      error: createPhoneNumbersRequestTransactionError,
    },
  ] = useMutation<
    MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutation,
    MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutationVariables
  >(MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction);

  const getDateFromWorkflowFilter = useCallback(
    (workflowId: number | null) => {
      if (!workflowId) return null;
      const workflow =
        workflowsData?.organization?.phoneNumberRequestWorkflows.find(
          (w) => w.id === workflowId,
        );

      const dateFilter = workflow?.filter_logic?.find(
        (filter) =>
          filter.filter_type === "Date" &&
          (filter.filter_op === ">=" || filter.filter_op === "GTE") &&
          ["CreatedDate", "hs_timestamp", "createdate"].includes(
            filter.filter_property,
          ),
      );

      return dateFilter?.filter_value
        ? new Date(dateFilter.filter_value[0])
        : null;
    },
    [workflowsData],
  );

  useEffect(() => {
    if (selectedWorkflowId) {
      const filterDate = getDateFromWorkflowFilter(selectedWorkflowId);
      setSelectedDate(filterDate || DEFAULT_LOOK_BACK_PERIOD);
    } else {
      setSelectedDate(DEFAULT_LOOK_BACK_PERIOD);
    }
  }, [selectedWorkflowId, getDateFromWorkflowFilter]);

  useEffect(() => {
    if (!isOpen) {
      if (startingWorkflowId) {
        const filterDate = getDateFromWorkflowFilter(startingWorkflowId);
        setSelectedDate(filterDate || DEFAULT_LOOK_BACK_PERIOD);
      } else {
        setSelectedDate(DEFAULT_LOOK_BACK_PERIOD);
      }
    }
  }, [isOpen, segmentationType, startingWorkflowId, getDateFromWorkflowFilter]);

  useEffect(() => {
    if (selectedWorkflowId && isOpen) {
      setNewPhoneNumberRequestsLoadingProgress(0);
      startNewPhoneNumberRequestJob({
        variables: {
          workflowId: selectedWorkflowId || 0,
          after: selectedDate ? format(selectedDate, "yyyy-MM-dd") : null,
        },
        onCompleted(data) {
          setJobId(data.startFindNewPhoneNumberRequestsJob.jobId);
        },
      });
    } else {
      setJobId(null);
    }
  }, [selectedWorkflowId, selectedDate, startNewPhoneNumberRequestJob, isOpen]);

  const contactsToEnrich =
    contactsToEnrichData?.newPhoneNumberRequestsStatus?.phoneNumberRequests?.filter(
      (request) =>
        includePreviouslyEnriched || !request.orgContact.lastEnrichedByCiroAt,
    ) || [];

  const creditsNeeded =
    (contactsToEnrichData?.newPhoneNumberRequestsStatus?.creditsPerRequest ||
      0) * selectedContactIds.size;
  const creditsAvailable =
    workflowsData?.organization?.credit_plan?.balance || 0;

  const tablePhoneNumberRequests = useFragment(
    MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragmentDoc,
    contactsToEnrichData?.newPhoneNumberRequestsStatus,
  );

  let errorMsg = null;
  if (createPhoneNumbersRequestTransactionError) {
    errorMsg = createPhoneNumbersRequestTransactionError.message;
  } else if (contactsToEnrichData?.newPhoneNumberRequestsStatus?.errorMessage) {
    errorMsg = contactsToEnrichData.newPhoneNumberRequestsStatus.errorMessage;
  } else if (creditsAvailable < creditsNeeded) {
    errorMsg = "You do not have enough credits to run this enrichment";
  }

  const showLoadingBar =
    startNewPhoneNumberRequestJobLoading ||
    (jobId && newPhoneNumberRequestsLoadingProgress !== 1);

  const isDateBeforeWorkflowFilter = useMemo(() => {
    if (!selectedWorkflowId || !selectedDate) return false;
    const filterDate = getDateFromWorkflowFilter(selectedWorkflowId);
    return filterDate && selectedDate < filterDate;
  }, [selectedWorkflowId, selectedDate, getDateFromWorkflowFilter]);

  return (
    <CiroModal isOpen={isOpen} onClose={onClose} size="LARGE">
      <div className={classNames("ciro-v1-pb-16")}>
        <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
          {workflowsLoading && (
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20px"} />
          )}
          {!workflowsLoading && (
            <div
              className={classNames(
                "ciro-v1-text-lg",
                "ciro-v1-font-semibold",
                "ciro-v1-pb-2",
              )}
            >
              {startingWorkflowId
                ? `Run workflow: ${
                    workflowOptions.find(
                      (option) => option.value === startingWorkflowId,
                    )?.label
                  }`
                : "Run now"}
            </div>
          )}
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close Run Mobile Numbers modal"
            onClick={onClose}
          >
            <XIcon />
          </CiroButton>
        </div>
        <div
          className={classNames("ciro-v1-min-h-[5rem]", "ciro-v1-max-h-[80vh]")}
        >
          <div className={classNames("ciro-v1-mb-4")}>
            {!startingWorkflowId && (
              <CiroDropdown
                isMulti={false}
                label={"Workflow"}
                options={workflowOptions}
                value={selectedWorkflowId}
                onChange={(value) => {
                  setSelectedWorkflowId(value);
                  setSegmentationType(workflowIdMap[value]?.segmentationType);
                  setIncludePreviouslyEnriched(false);
                }}
                maxMenuHeight={100}
                isDisabled={Boolean(startingWorkflowId)}
              />
            )}
          </div>
          <div>
            <div className={classNames("ciro-v1-pb-2")}>
              Run for {callsOrContacts} created after
            </div>
            <CiroDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              maxDate={new Date()}
              placeholderText="No lookback period"
            />
            {isDateBeforeWorkflowFilter && (
              <div
                className={classNames(
                  "ciro-v1-text-amber-700",
                  "ciro-v1-bg-amber-50",
                  "ciro-v1-mt-2",
                  "ciro-v1-p-2",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-text-xs",
                )}
              >
                Inputted date is before cutoff date set in workflow settings,
                and will have no effect.
              </div>
            )}
          </div>
          <div className={classNames("ciro-v1-py-4")}>
            {segmentationType ===
              PhoneNumberRequestSegmentTypeEnum.CrmRecordValues && (
              <div className={classNames("ciro-v1-pb-2")}>
                <div className={classNames("ciro-v1-pb-2")}>
                  Run over previously enriched contacts
                </div>
                <CiroSwitch
                  checked={includePreviouslyEnriched}
                  onChange={(checked) => setIncludePreviouslyEnriched(checked)}
                />
              </div>
            )}

            {showLoadingBar && (
              <div className={classNames("ciro-v1-text-sm")}>
                <LoadingBar
                  height={loadingBarHeight.LARGE}
                  customFillPercentage={
                    startNewPhoneNumberRequestJobLoading
                      ? 0
                      : newPhoneNumberRequestsLoadingProgress
                  }
                />
              </div>
            )}

            {!showLoadingBar &&
              selectedWorkflowId &&
              tablePhoneNumberRequests && (
                <MobileNumbersRunPreviewSelection
                  creditsNeeded={creditsNeeded}
                  phoneNumberRequests={tablePhoneNumberRequests}
                  selectedContactIds={selectedContactIds}
                  setSelectedContactIds={setSelectedContactIds}
                  includePreviouslyEnriched={includePreviouslyEnriched}
                />
              )}
          </div>
          <div className={classNames("ciro-v1-pb-4")}>
            <CiroButton
              analyticsField="Run Mobile Numbers"
              style={CiroButtonStyleEnum.LOUD}
              onClick={() => {
                createPhoneNumbersRequestTransaction({
                  variables: {
                    workflowId: selectedWorkflowId || 0,
                    phoneNumberRequests: contactsToEnrich
                      .filter((contact) =>
                        selectedContactIds.has(contact.orgContact.external_id),
                      )
                      .map((contact) => ({
                        org_phone_call_id: contact.org_phone_call_id,
                        org_contact: {
                          external_object_type:
                            contact.orgContact.external_object_type,
                          external_id: contact.orgContact.external_id,
                          linkedin_id: contact.orgContact.linkedin_id,
                          first_name: contact.orgContact.first_name,
                          last_name: contact.orgContact.last_name,
                          company_name: contact.orgContact.company_name,
                          company_website_domain:
                            contact.orgContact.company_website_domain,
                          source: contact.orgContact.source,
                          org_phone_numbers:
                            contact.orgContact.orgPhoneNumbers.map(
                              (phoneNumber) => ({
                                phone_number: phoneNumber.phone_number,
                                external_phone_field:
                                  phoneNumber.external_phone_field,
                                external_status: phoneNumber.external_status,
                              }),
                            ),
                        },
                      })),
                  },
                  onCompleted(data) {
                    if (data.createNewPhoneNumbersRequestTransaction.id) {
                      onClose();
                    }
                  },
                  refetchQueries: [
                    MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions,
                  ],
                });
              }}
              customClassName={classNames("ciro-v1-w-full", "ciro-v1-mb-8")}
              disabled={
                !selectedWorkflowId ||
                startNewPhoneNumberRequestJobLoading ||
                createPhoneNumbersRequestTransactionLoading ||
                selectedContactIds.size === 0
              }
            >
              Run
            </CiroButton>
            {errorMsg && (
              <div
                className={classNames(
                  "ciro-v1-text-red-900",
                  "ciro-v1-bg-red-100",
                  "ciro-v1-mt-2",
                  "ciro-v1-p-2",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-text-xs",
                )}
              >
                {errorMsg}
              </div>
            )}
          </div>
        </div>
      </div>
    </CiroModal>
  );
};

export default MobileNumbersRunModal;
