import classNames from "classnames";
import { PencilIcon, ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import SkeletonLoading from "../shared/SkeletonLoading";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { dateToTimeAgo } from "../../utils/formatters";
import { gql } from "@apollo/client";
import { ContactListTable_ContactListFragment } from "../../__generated__/graphql";
import DeleteContactListModal from "./DeleteContactListModal";
import UpsertContactListModal from "./UpsertContactListModal";
import { useContext, useState } from "react";
import fileDownload from "js-file-download";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

interface IContactListTable {
  contactLists: ContactListTable_ContactListFragment[];
  loading: boolean;
  refetch: () => void;
}

export const ContactListTable_ContactList = gql`
  fragment ContactListTable_ContactList on ContactList {
    id
    name
    contactCount
    created_at
  }
`;

const ContactListTable = ({ 
  contactLists, 
  loading,
  refetch,
}: IContactListTable) => {
  const { accessToken } = useContext(AppContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<ContactListTable_ContactListFragment | null>(null);
  const [downloadingListIds, setDownloadingListIds] = useState<Set<number>>(new Set());

  const handleDeleteContactList = (list: ContactListTable_ContactListFragment) => {
    setSelectedList(list);
    setDeleteModalOpen(true);
  };

  const handleEditContactList = (list: ContactListTable_ContactListFragment) => {
    setSelectedList(list);
    setEditModalOpen(true);
  };

  const handleDownloadContactList = async (list: ContactListTable_ContactListFragment) => {
    try {
      setDownloadingListIds(prev => new Set([...prev, list.id]));
      const exportResponse = await fetch(`/export-contact-list-saved-contacts`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactListId: list.id,
        }),
      });
      const blob = await exportResponse.blob();
      fileDownload(blob, `Contact List - ${list.name} - Contacts.csv`);
    } catch (error) {
      console.error("Error during CSV download:", error);
    } finally {
      setDownloadingListIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(list.id);
        return newSet;
      });
    }
  };

  if (loading) {
    return <SkeletonLoading numSkeletons={5} skeletonHeight={"3rem"} />;
  }

  const headers = [
    <CiroTableHeader key="name" isFirst={true} className="w-full">
      Name
    </CiroTableHeader>,
    <CiroTableHeader
      key="contacts"
      className={classNames("ciro-v1-hidden", "md:ciro-v1-table-cell")}
    >
      Contacts
    </CiroTableHeader>,
    <CiroTableHeader key="created">Created</CiroTableHeader>,
    <CiroTableHeader key="actions" isLast={true}>
      Actions
    </CiroTableHeader>,
  ];

  return (
    <>
      <CiroTable>
        <thead className={classNames("ciro-v1-table-header-group")}>
          <CiroTableRow clickable={false}>{headers}</CiroTableRow>
        </thead>
        <tbody className={classNames("ciro-v1-table-row-group")}>
          {contactLists?.map((list) => (
            <CiroTableRow key={list.id} clickable={false}>
              <CiroTableCell className="w-full">{list.name}</CiroTableCell>
              <CiroTableCell
                className={classNames(
                  "ciro-v1-hidden",
                  "md:ciro-v1-table-cell",
                )}
              >
                {list.contactCount || "-"}
              </CiroTableCell>
              <CiroTableCell>{dateToTimeAgo(list.created_at)}</CiroTableCell>
              <CiroTableCell>
                <div
                  className={classNames(
                    "ciro-v1-flex",
                    "ciro-v1-gap-4",
                    "ciro-v1-justify-start",
                  )}
                >
                  <CiroButton
                    analyticsField="edit-list"
                    analyticsProps={{ listId: list.id }}
                    style={CiroButtonStyleEnum.UNSTYLED}
                    onClick={() => handleEditContactList(list)}
                  >
                    <PencilIcon
                      className={classNames("ciro-v1-h-4", "ciro-v1-w-4")}
                    />
                  </CiroButton>
                  <CiroButton
                    analyticsField="download-list"
                    analyticsProps={{ listId: list.id }}
                    style={CiroButtonStyleEnum.UNSTYLED}
                    onClick={() => handleDownloadContactList(list)}
                  >
                    {downloadingListIds.has(list.id) ? (
                      <CiroSpinner loading={true} />
                    ) : (
                      <ArrowDownTrayIcon
                        className={classNames("ciro-v1-h-4", "ciro-v1-w-4")}
                      />
                    )}
                  </CiroButton>
                  <CiroButton
                    analyticsField="delete-list"
                    analyticsProps={{ listId: list.id }}
                    style={CiroButtonStyleEnum.UNSTYLED}
                    onClick={() => handleDeleteContactList(list)}
                  >
                    <TrashIcon
                      className={classNames("ciro-v1-h-4", "ciro-v1-w-4")}
                    />
                  </CiroButton>
                </div>
              </CiroTableCell>
            </CiroTableRow>
          ))}
        </tbody>
      </CiroTable>

      {selectedList && (
        <>
          <DeleteContactListModal
            isOpen={deleteModalOpen}
            listId={selectedList.id}
            listName={selectedList.name}
            onClose={() => {
              setDeleteModalOpen(false);
              setSelectedList(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
          />
          <UpsertContactListModal
            isOpen={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedList(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
            initialData={{
              id: selectedList.id,
              name: selectedList.name,
            }}
          />
        </>
      )}
    </>
  );
};

export default ContactListTable; 