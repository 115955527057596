const CiroIcon = ({ width = "40", height = "40" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0.519531C9.70535 0.519531 1.21563 8.24747 0.0155666 18.2187C-0.145128 19.5539 0.960534 20.6492 2.30536 20.6492H37.6946C39.0395 20.6492 40.1451 19.5538 39.9844 18.2187C38.7844 8.24747 30.2947 0.519531 20 0.519531Z"
        fill="url(#paint0_linear_4756_31441)"
      />
      <mask
        id="mask0_4756_31441"
        maskUnits="userSpaceOnUse"
        x="0"
        y="22"
        width="40"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.81823 22.9216C1.10097 22.9216 0.519531 23.5031 0.519531 24.2204C0.519531 24.9376 1.10097 25.5191 1.81823 25.5191H38.1815C38.8988 25.5191 39.4803 24.9376 39.4803 24.2204C39.4803 23.5031 38.8988 22.9216 38.1815 22.9216H1.81823ZM3.11691 27.792C2.39965 27.792 1.81821 28.3735 1.81821 29.0907C1.81821 29.8079 2.39965 30.3893 3.11691 30.3893H36.8829C37.6002 30.3893 38.1815 29.8079 38.1815 29.0907C38.1815 28.3735 37.6002 27.792 36.8829 27.792H3.11691ZM5.06462 33.636C5.06462 32.9187 5.64606 32.3372 6.3633 32.3372H33.6358C34.353 32.3372 34.9345 32.9187 34.9345 33.636C34.9345 34.3532 34.353 34.9347 33.6358 34.9347H6.3633C5.64606 34.9347 5.06462 34.3532 5.06462 33.636ZM14.1554 36.8828C13.4382 36.8828 12.8568 37.4643 12.8568 38.1816C12.8568 38.8988 13.4382 39.4803 14.1554 39.4803H25.8436C26.5609 39.4803 27.1424 38.8988 27.1424 38.1816C27.1424 37.4643 26.5609 36.8828 25.8436 36.8828H14.1554Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4756_31441)">
        <path
          d="M19.9998 40.7796C8.88249 40.7796 -0.129883 31.7672 -0.129883 20.6499H40.1295C40.1295 31.7672 31.1171 40.7796 19.9998 40.7796Z"
          fill="url(#paint1_linear_4756_31441)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4756_31441"
          x1="20"
          y1="0.519531"
          x2="20"
          y2="20.6492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9706" />
          <stop offset="1" stop-color="#F45822" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4756_31441"
          x1="19.9998"
          y1="40.7796"
          x2="19.9998"
          y2="20.6499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9706" />
          <stop offset="1" stop-color="#F45822" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CiroIcon; 