import { gql } from "@apollo/client";
import { useMemo } from "react";
import { OrgContactObjectFields_OrganizationFragment } from "../../../__generated__/graphql";

const SALESFORCE_SUPPORTED_FILTER_FIELD_TYPES = new Set([
  "id",
  "boolean",
  // "reference",
  "string",
  "picklist",
  "textarea",
  // "double",
  "address",
  "phone",
  "email",
  // "date",
  // "datetime",
  "url",
  // "currency",
  // "multipicklist",
  "location",
]);

const SALESFORCE_SUPPORTED_TIMESTAMP_FIELD_TYPES = new Set([
  "date",
  "datetime",
]);

const HUBSPOT_SUPPORTED_FILTER_FIELD_TYPES = new Set([
  "string",
  "number",
  "enumeration",
  // "datetime",
  // "date",
  "bool",
  // "object_coordinates",
  "phone_number",
]);

const HUBSPOT_SUPPORTED_TIMESTAMP_FIELD_TYPES = new Set([
  "date",
  "datetime",
]);

export const OrgContactObjectFields_Organization = gql`
  fragment OrgContactObjectFields_Organization on Organization {
    organizationMergeIntegration {
      orgContactObjectFields(customObjectType: $customObjectType) {
        crmRecordType
        fieldName
        fieldLabel
        fieldType
        fieldPicklist {
          value
          label
          active
        }
      }
    }
  }
`;

export enum OrgContactFieldUseCase {
  FILTER = "Filter",
  TIMESTAMP = "Timestamp"
} 

const useOrgContactObjectFields = ({
  organization,
  isHubspot,
  isSalesforce,
}: {
  organization: OrgContactObjectFields_OrganizationFragment | null;
  isHubspot: boolean;
  isSalesforce: boolean;
}) => {
  return useMemo(() => {
    const contactFields =
      organization?.organizationMergeIntegration?.orgContactObjectFields || [];
    const fieldTypes: Record<string, string> = {};
    const filterFields: Array<{ label: string; value: string }> = [];
    const timestampFields: Array<{ label: string; value: string }> = [];
    const picklistOptions: Record<
      string,
      Array<{ label: string; value: string }>
    > = {};

    contactFields.forEach((field) => {
      if (field.fieldName) {
        fieldTypes[field.fieldName] = field.fieldType;
        
        const isFilterFieldSupported =
          (isHubspot && HUBSPOT_SUPPORTED_FILTER_FIELD_TYPES.has(field.fieldType)) ||
          (isSalesforce && SALESFORCE_SUPPORTED_FILTER_FIELD_TYPES.has(field.fieldType));
        
        const isTimestampFieldSupported =
          (isHubspot && HUBSPOT_SUPPORTED_TIMESTAMP_FIELD_TYPES.has(field.fieldType)) ||
          (isSalesforce && SALESFORCE_SUPPORTED_TIMESTAMP_FIELD_TYPES.has(field.fieldType));
        
        if (isFilterFieldSupported) {
          filterFields.push({
            label: field.fieldLabel,
            value: field.fieldName,
          });
        }
        
        if (isTimestampFieldSupported) {
          timestampFields.push({
            label: field.fieldLabel,
            value: field.fieldName,
          });
        }

        if (
          field.fieldPicklist && (
            (isHubspot && HUBSPOT_SUPPORTED_FILTER_FIELD_TYPES.has(field.fieldType)) ||
            (isSalesforce && SALESFORCE_SUPPORTED_FILTER_FIELD_TYPES.has(field.fieldType))
          )
        ) {
          picklistOptions[field.fieldName] = field?.fieldPicklist?.map(
            (item) => ({
              label: item.label || "",
              value: item.value || "",
            }),
          ) || [];
        }
      }
    });
    
    return {
      fieldTypes,
      filterFields,
      timestampFields,
      picklistOptions,
    };
  }, [organization, isSalesforce, isHubspot]);
};

export default useOrgContactObjectFields;
