import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import CiroDatePicker from "../shared/CiroDatePicker";
import classNames from "classnames";
import { PhoneNumberRequestSegmentTypeEnum } from "../../__generated__/graphql";

interface IFilterCutoffDateOptionProps {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
}

const FilterCutoffDateOption = ({
  sharedWorkflowFormProperties,
  isLoadingWorkflow,
}: IFilterCutoffDateOptionProps) => {
  const { watch, setValue } = sharedWorkflowFormProperties;

  const date = watch("date_filter");
  const segmentType = watch("segmentation.segment_type");

  return (
    <CiroNumbersFormSection
      title="Date filter"
      description={`Only process ${
        segmentType === PhoneNumberRequestSegmentTypeEnum.CallDisposition
          ? "calls"
          : "contacts"
      } created after this date.`}
      isLoading={isLoadingWorkflow}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <div className={classNames("ciro-v1-mr-4")}>
          Only process{" "}
          {segmentType === PhoneNumberRequestSegmentTypeEnum.CallDisposition
            ? "calls"
            : "contacts"}{" "}
          created after this date:
        </div>
        <CiroDatePicker
          placeholderText=""
          selectedDate={date}
          setSelectedDate={(date) => setValue("date_filter", date)}
        />
      </div>
    </CiroNumbersFormSection>
  );
};

export default FilterCutoffDateOption;
