import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroButton, { CiroButtonStyleEnum } from "../../components/shared/CiroButton";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import CiroIcon from "../../assets/img/icons/CiroIcon";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const AutomationSplashPage = ({ onCreateNewList }: { onCreateNewList: () => void }) => {
  return (
    <CiroContainer
      className={classNames(
        "ciro-v1-bg-neutral-100",
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-items-center",
        "ciro-v1-justify-center",
        "ciro-v1-min-h-[80vh]",
        "ciro-v1-w-full",
        "xl:ciro-v1-px-80",
        "sm:ciro-v1-px-20",
      )}
    >
      <div
        className={classNames([
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-items-center",
          "ciro-v1-justify-center",
          "ciro-v1-min-h-[80vh]",
          "ciro-v1-w-full",
          "ciro-v1-px-4",
        ])}
      >
        <div
          className={classNames([
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-gap-4",
            "ciro-v1-mb-8",
          ])}
        >
          <div
            className={classNames(
              "ciro-v1-bg-white",
              "ciro-v1-h-[80px]",
              "ciro-v1-w-[80px]",
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-justify-center",
              "ciro-v1-rounded-lg",
              "ciro-v1-shadow-sm",
            )}
          >
            <CiroIcon width="32" height="32" />
          </div>

          <div
            className={classNames(
              "ciro-v1-bg-neutral-100",
              "ciro-v1-rounded-full",
              "ciro-v1-mx-[-30px]",
              "ciro-v1-z-10",
              "ciro-v1-p-2"
            )}
          >
            <ArrowPathIcon className="ciro-v1-w-6 ciro-v1-h-6 ciro-v1-text-orange-600" />
          </div>

          <div
            className={classNames(
              "ciro-v1-bg-white",
              "ciro-v1-rounded-lg",
              "ciro-v1-h-[80px]",
              "ciro-v1-w-[80px]",
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-justify-center",
              "ciro-v1-shadow-sm",
            )}
          >
            <LinkedInIcon />
          </div>
        </div>

        <h1
          className={classNames([
            "ciro-v1-text-2xl",
            "ciro-v1-font-semibold",
            "ciro-v1-mb-4",
            "ciro-v1-text-center",
          ])}
        >
          Start automating your workflow now
        </h1>

        <p
          className={classNames([
            "ciro-v1-text-center",
            "ciro-v1-text-gray-600",
            "ciro-v1-max-w-lg",
            "ciro-v1-mb-8",
          ])}
        >
          Ciro helps you spend less time doing manual work by automating
          research on LinkedIn. Create a list, define some personas you'd like to add, and we'll do the heavy lifting to
          find high-fit contacts on LinkedIn.
        </p>

        <CiroButton
          onClick={onCreateNewList}
          analyticsField="Create new contact list"
          style={CiroButtonStyleEnum.LOUD}
        >
          Create new list
        </CiroButton>
      </div>
    </CiroContainer>
  );
};

export default AutomationSplashPage;
