import classNames from "classnames";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import { gql, useQuery } from "@apollo/client";
import {
  MobileNumbersTransactionsTable_PhoneNumbeRequestTransactionsQuery,
  MobileNumbersTransactionsTable_PhoneNumbeRequestTransactionsQueryVariables,
  PhoneNumbersRequestTransactionStatusEnum,
} from "../../../__generated__/graphql";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import { format } from "date-fns";
import CiroPill from "../../shared/CiroPill";
import DotIcon from "../../../assets/img/icons/DotIcon";
import MobileNumbersDownloadButton from "../MobileNumbersDownloadButton";
import SkeletonLoading from "../../shared/SkeletonLoading";
import { useState } from "react";
import CiroTablePagination from "../../shared/CiroTable/CiroTablePagination";

export const MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions = gql`
  query MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions(
    $limit: Int!
    $offset: Int!
  ) {
    batchTransactions: phoneNumbersRequestTransactions(
      requestType: batch
      limit: $limit
      offset: $offset
    ) {
      transactions {
        id
        created_at
        requestedContactsCount
        newNumbersCount
        numbersCalledCount
        conversationsCreatedCount
        status
        creditsUsed
        workflow {
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

const statusOptions = {
  [PhoneNumbersRequestTransactionStatusEnum.InProgress]: {
    name: "In Progress",
    fontColor: "ciro-v1-text-amber-800",
    background: "ciro-v1-bg-amber-100",
    dotColor: "ciro-v1-text-amber-600",
  },
  [PhoneNumbersRequestTransactionStatusEnum.Completed]: {
    name: "Completed",
    fontColor: "ciro-v1-text-green-800",
    background: "ciro-v1-bg-green-100",
    dotColor: "ciro-v1-text-green-600",
  },
  [PhoneNumbersRequestTransactionStatusEnum.Failed]: {
    name: "Failed",
    fontColor: "ciro-v1-text-red-800",
    background: "ciro-v1-bg-red-100",
    dotColor: "ciro-v1-text-red-600",
  },
};

const MobileNumbersTransactionsTable = () => {
  const [page, setPage] = useState(0);
  const limit = 10; // or whatever number you want per page

  const {
    data: phoneNumberRequestTransactionsData,
    loading: phoneNumberRequestTransactionsLoading,
  } = useQuery<
    MobileNumbersTransactionsTable_PhoneNumbeRequestTransactionsQuery,
    MobileNumbersTransactionsTable_PhoneNumbeRequestTransactionsQueryVariables
  >(MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions, {
    variables: {
      limit,
      offset: page * limit,
    },
  });
  const headers = [
    <CiroTableHeader key="start-time-of-run" isFirst={true}>
      Date
    </CiroTableHeader>,
    <CiroTableHeader
      key="source-workflow"
      align={CiroTableHeaderAlignEnum.LEFT}
    >
      Workflow
    </CiroTableHeader>,
    <CiroTableHeader
      key="requested-contacts"
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      Requested contacts
    </CiroTableHeader>,
    <CiroTableHeader key="new-numbers" align={CiroTableHeaderAlignEnum.RIGHT}>
      New numbers
    </CiroTableHeader>,
    <CiroTableHeader
      key="numbers-called"
      align={CiroTableHeaderAlignEnum.RIGHT}
      className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
    >
      Numbers called
    </CiroTableHeader>,
    <CiroTableHeader
      key="conversations-created"
      align={CiroTableHeaderAlignEnum.RIGHT}
      className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
    >
      Conversations created
    </CiroTableHeader>,
    <CiroTableHeader
      key="status"
      className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
    >
      Status
    </CiroTableHeader>,
    <CiroTableHeader key="credits-used">Credits used</CiroTableHeader>,
    <CiroTableHeader
      key="download"
      isLast={true}
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      &nbsp;
    </CiroTableHeader>,
  ];

  if (phoneNumberRequestTransactionsLoading) {
    return <SkeletonLoading numSkeletons={25} skeletonHeight={"3rem"} />;
  }

  if (!phoneNumberRequestTransactionsData) {
    return null;
  }

  return (
    <CiroTable>
      <thead className={classNames("ciro-v1-table-header-group")}>
        <CiroTableRow clickable={false}>{headers}</CiroTableRow>
      </thead>
      <tbody className={classNames("ciro-v1-table-row-group")}>
        {phoneNumberRequestTransactionsData.batchTransactions.transactions.map(
          (phoneNumberRequestTransaction) => {
            const statusOption =
              statusOptions[phoneNumberRequestTransaction.status];

            return (
              <CiroTableRow
                key={phoneNumberRequestTransaction.id}
                clickable={false}
              >
                <CiroTableCell>
                  {format(
                    new Date(phoneNumberRequestTransaction.created_at),
                    "yyyy-MM-dd HH:mm",
                  )}
                </CiroTableCell>
                <CiroTableCell>
                  <div
                    className={classNames(
                      "ciro-v1-flex",
                      "ciro-v1-items-center",
                      "ciro-v1-gap-1",
                    )}
                  >
                    {phoneNumberRequestTransaction.workflow?.name || "N/A"}
                  </div>
                </CiroTableCell>

                <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                  {phoneNumberRequestTransaction.requestedContactsCount}
                </CiroTableCell>
                <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                  {phoneNumberRequestTransaction.newNumbersCount}
                </CiroTableCell>
                <CiroTableCell
                  className={classNames(
                    "ciro-v1-hidden",
                    "sm:ciro-v1-table-cell",
                  )}
                  align={CiroTableCellAlignEnum.RIGHT}
                >
                  {phoneNumberRequestTransaction.numbersCalledCount > 0
                    ? phoneNumberRequestTransaction.numbersCalledCount
                    : "-"}
                </CiroTableCell>
                <CiroTableCell
                  className={classNames(
                    "ciro-v1-hidden",
                    "sm:ciro-v1-table-cell",
                  )}
                  align={CiroTableCellAlignEnum.RIGHT}
                >
                  {phoneNumberRequestTransaction.conversationsCreatedCount > 0
                    ? phoneNumberRequestTransaction.conversationsCreatedCount
                    : "-"}
                </CiroTableCell>
                <CiroTableCell
                  className={classNames(
                    "ciro-v1-hidden",
                    "sm:ciro-v1-table-cell",
                  )}
                >
                  <CiroPill
                    fontColor={statusOption.fontColor}
                    background={statusOption.background}
                  >
                    <div
                      className={classNames(
                        "ciro-v1-flex",
                        "ciro-v1-items-center",
                        "ciro-v1-gap-1",
                      )}
                    >
                      <span className={classNames(statusOption.dotColor)}>
                        <DotIcon />
                      </span>
                      {statusOption.name}
                    </div>
                  </CiroPill>
                </CiroTableCell>
                <CiroTableCell>
                  {phoneNumberRequestTransaction.creditsUsed}
                </CiroTableCell>
                <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                  <MobileNumbersDownloadButton
                    ids={[phoneNumberRequestTransaction.id]}
                  />
                </CiroTableCell>
              </CiroTableRow>
            );
          },
        )}
        <CiroTablePagination
          setOffset={(newOffset) => setPage(Math.floor(newOffset / limit))}
          offset={page * limit}
          perPage={limit}
          tableRows={headers.length}
          totalCount={
            phoneNumberRequestTransactionsData?.batchTransactions?.totalCount ||
            0
          }
        />
      </tbody>
    </CiroTable>
  );
};

export default MobileNumbersTransactionsTable;
