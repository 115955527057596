import classNames from "classnames";
import { ReactNode } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTitle from "../shared/CiroTitle";

interface IAutomationHomeTableSection {
  buttonHandler: () => void;
  objectType: "list" | "persona";
  table?: ReactNode;
}

const AutomationHomeTableSection = ({
  buttonHandler,
  objectType,
  table,
}: IAutomationHomeTableSection) => {
  return (
    <>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
          "ciro-v1-w-full",
          "ciro-v1-my-4",
          "ciro-v1-items-start",
        )}
      >
        <CiroTitle
          title={objectType === "list" ? "My contact lists" : "My personas"}
          size="small"
        />
        <CiroButton
          analyticsField={`Create new ${objectType}`}
          style={CiroButtonStyleEnum.LOUD}
          onClick={buttonHandler}
        >
          + New {objectType}
        </CiroButton>
      </div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
          "ciro-v1-flex-col",
          "ciro-v1-w-full",
          "ciro-v1-mb-4",
        )}
      >
        {table || (
          <div
            className={classNames(
              "ciro-v1-text-start",
              "ciro-v1-text-gray-500",
              "ciro-v1-text-sm",
              "ciro-v1-w-full",
            )}
          >
            {objectType === "list"
              ? "No lists found. Lists are collections of profiles you scrape from LinkedIn."
              : "No personas found. Personas are archetypes you categorize your leads into, e.g., CEOs, CTOs, VPs of Finance, etc."}
          </div>
        )}
      </div>
    </>
  );
};

export default AutomationHomeTableSection;
