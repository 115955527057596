import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import logo from "../../assets/icon.png";
import CiroButton from "../shared/CiroButton";
import chromebw from "../../assets/img/logos/chrome-bw.png";
import { gql } from "@apollo/client";
import SkeletonLoading from "../shared/SkeletonLoading";
import TimeAgo from "javascript-time-ago";
import ChromeExtensionNumbersDownloadButton from "./ChromeExtensionNumbersDownloadButton";

export const ChromeExtensionCard_Fragment = gql`
  fragment ChromeExtensionCard_Fragment on Query {
    chromeExtensionTransactions: phoneNumbersRequestTransactions(
      requestType: chrome_extension
    ) {
      transactions {
        id
        created_at
        updated_at
        requestedContactsCount
        newNumbersCount
        numbersCalledCount
        conversationsCreatedCount
        creditsUsed
        status
        error
      }
      totalCount
    }
  }
`;

const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/ciro/poiofceiibpjdmhihnpaembimmnioifk?hl=en";

interface ChromeExtensionCardProps {
  chromeExtensionTransactions: {
    transactions: Array<{
      id: number;
      created_at: string;
      updated_at: string;
      requestedContactsCount: number;
      newNumbersCount: number;
      numbersCalledCount: number;
      conversationsCreatedCount: number;
      creditsUsed: number;
      status: 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';
      error?: string;
    }>;
    totalCount: number;
  } | null;
}

const ChromeExtensionCard = ({
  chromeExtensionTransactions,
}: ChromeExtensionCardProps) => {
  const formatLastEnrichedAt = (dateString: string): React.ReactNode => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const isYesterday =
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();

    if (isToday || isYesterday) {
      const timeString = date
        .toLocaleTimeString([], {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .replace(/\s?([AP]M)$/, (match, p1) => p1.toLowerCase());

      let datePart: string;
      if (isToday) {
        datePart = "Today";
      } else {
        datePart = "Yesterday";
      }

      return (
        <>
          <span className="ciro-v1-font-medium">{datePart}</span> at{" "}
          <span className="ciro-v1-font-medium">{timeString}</span>
        </>
      );
    } else {
      const timeAgo = new TimeAgo("en-US");
      return timeAgo.format(date);
    }
  };

  const mostRecentTransaction = chromeExtensionTransactions?.transactions?.reduce(
    (latest, transaction) =>
      !latest || new Date(transaction.updated_at) > new Date(latest)
        ? transaction.updated_at
        : latest,
    null as string | null,
  );
  const requestedContactsCount = chromeExtensionTransactions?.transactions?.reduce(
    (acc, transaction) => acc + transaction.requestedContactsCount,
    0,
  );
  const newNumbersCount = chromeExtensionTransactions?.transactions?.reduce(
    (acc, transaction) => acc + transaction.newNumbersCount,
    0,
  );

  if (!chromeExtensionTransactions) {
    return (
      <div className={classNames("ciro-v1-w-full", "ciro-v1-p-5")}>
        <CiroTitle title="Chrome extension" size="small" />
        <SkeletonLoading numSkeletons={1} skeletonHeight="7rem" />
      </div>
    );
  }

  return (
    <div className={classNames("ciro-v1-w-full", "ciro-v1-p-5")}>
      <CiroTitle title="Chrome extension" size="small" />
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-border-1",
          "ciro-v1-rounded-xl",
          "ciro-v1-justify-between",
          "ciro-v1-bg-white",
          "ciro-v1-text-neutral-700",
        )}
      >
        {chromeExtensionTransactions.transactions.length > 0 ? (
          <div
            className={classNames(
              "ciro-v1-w-full",
              "ciro-v1-flex",
              "ciro-v1-justify-between",
            )}
          >
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-h-28",
                "ciro-v1-items-center",
              )}
            >
              <div className={classNames("ciro-v1-ml-5")}>
                <div
                  className={classNames(
                    "ciro-v1-text-xl",
                    "ciro-v1-text-neutral-700",
                  )}
                >
                  {requestedContactsCount || 0}
                </div>
                <div
                  className={classNames(
                    "ciro-v1-text-sm",
                    "ciro-v1-text-gray-500",
                  )}
                >
                  Requested contacts
                </div>
              </div>
              <div className={classNames("ciro-v1-ml-10")}>
                <div
                  className={classNames(
                    "ciro-v1-text-xl",
                    "ciro-v1-text-neutral-700",
                  )}
                >
                  {newNumbersCount || 0}
                </div>
                <div
                  className={classNames(
                    "ciro-v1-text-sm",
                    "ciro-v1-text-gray-500",
                  )}
                >
                  New numbers
                </div>
              </div>
              <div className={classNames("ciro-v1-ml-16")}>
                <div
                  className={classNames(
                    "ciro-v1-text-lg",
                    "ciro-v1-text-neutral-700",
                  )}
                >
                  {mostRecentTransaction
                    ? formatLastEnrichedAt(mostRecentTransaction)
                    : "-"}
                </div>
                <div
                  className={classNames(
                    "ciro-v1-text-sm",
                    "ciro-v1-text-gray-500",
                  )}
                >
                  Last enriched
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-mr-5",
              )}
            >
              <ChromeExtensionNumbersDownloadButton
                ids={chromeExtensionTransactions.transactions.map((transaction) =>
                  transaction.id,
                )}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-p-4",
                "ciro-v1-gap-4",
              )}
            >
              <div
                className={classNames(
                  "ciro-v1-border-1",
                  "ciro-v1-p-2",
                  "ciro-v1-rounded-lg",
                )}
              >
                <img src={logo} alt="Ciro logo" width="48" height="48" />
              </div>
              <CiroTitle
                title="Ciro Numbers"
                subtitle="Enhance your prospecting by effortlessly finding and managing contact phone numbers in real-time."
                size="small"
              />
            </div>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-mr-2",
              )}
            >
              <CiroButton
                analyticsField="install-chrome-extension"
                customClassName={classNames(
                  "ciro-v1-h-9",
                  "ciro-v1-min-w-[100px]",
                )}
                customPaddingY={classNames(
                  "ciro-v1-py-10",
                  "xl:ciro-v1-py-6",
                  "2xl:ciro-v1-py-3",
                )}
                onClick={() => {
                  window.open(CHROME_EXTENSION_URL, "_blank");
                }}
              >
                <div
                  className={classNames(
                    "ciro-v1-flex",
                    "ciro-v1-justify-between",
                    "ciro-v1-items-center",
                    "ciro-v1-w-full",
                  )}
                >
                  <div className={classNames("ciro-v1-mr-2")}>
                    <img
                      src={chromebw}
                      alt="Chrome Logo"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div>Install Chrome extension</div>
                </div>
              </CiroButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChromeExtensionCard;
