import { gql, useMutation } from "@apollo/client";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useState } from "react";
import { toast } from "react-hot-toast";
import CiroSpinner from "../shared/CiroSpinner";
import { DeletePersonaModal_PersonaFragment } from "../../__generated__/graphql";

export const DeletePersonaModal_Persona = gql`
  fragment DeletePersonaModal_Persona on Persona {
    contactCount
  }
`;

const DestroyPersonaModal_DestroyPersona = gql`
  mutation DestroyPersonaModal_DestroyPersona($id: Int!) {
    destroyPersona(id: $id) {
      success
      message
    }
  }
`;

interface DeletePersonaModalProps {
  isOpen: boolean;
  personaId: number;
  personaName: string;
  selectedPersona?: DeletePersonaModal_PersonaFragment | null;
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

const DeletePersonaModal = ({
  isOpen,
  personaId,
  personaName,
  selectedPersona,
  onClose,
  onSuccess,
}: DeletePersonaModalProps) => {
  const [destroyPersona, { loading: isDeleting }] = useMutation(DestroyPersonaModal_DestroyPersona, {
    async onCompleted(data) {
      if (data.destroyPersona.success) {
        setIsRefetching(true);
        try {
          await onSuccess();
          toast.success("Persona deleted successfully.");
        } finally {
          setIsRefetching(false);
          onClose();
        }
      }
    }
  });
  const [isRefetching, setIsRefetching] = useState(false);


  const handleDelete = async () => {
    try {
      await destroyPersona({
        variables: {
          id: personaId,
        },
      });
    } catch (error) {
      toast.error("Error deleting persona.");
      console.error("Error deleting persona:", error);
    }
  };

    const isLoading = isDeleting || isRefetching;


  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-gap-4",
        )}
      >
        <div className={classNames("ciro-v1-text-lg", "ciro-v1-font-medium")}>
          Delete persona
        </div>
        <div className="ciro-v1-flex ciro-v1-flex-col ciro-v1-gap-2">
          <div>
            Are you sure you want to delete{" "}
            <span className="ciro-v1-font-semibold">{personaName}</span>?
          </div>
          {selectedPersona && (
            <div className="ciro-v1-text-gray-600">
              This will remove this persona from {selectedPersona?.contactCount} {selectedPersona?.contactCount === 1 ? "contact" : "contacts"}.
            </div>
          )}
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-gap-4",
          )}
        >
          <CiroButton
            analyticsField="cancel-delete-persona"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="confirm-delete-persona"
            style={CiroButtonStyleEnum.DANGER}
            onClick={handleDelete}
            disabled={isLoading}
          >
            {isLoading ? (
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-gap-2",
                )}
              >
                <CiroSpinner loading={isLoading} />
                <div>Deleting...</div>
              </div>
            ) : (
              "Yes, delete"
            )}
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default DeletePersonaModal; 